import React from "react";
import { Helmet } from "react-helmet-async";
import Edit, { Columns } from "../../component/Edit";
import { changePassword, deleteUser, getRole, getUser, saveUser, UserData } from "../../api/admin";
import Toast from "../../component/toast";
import { CancelablePromise } from "../../util/client";
import { useCrumbs } from "../../component/breadcrumbs";
import useLocale from "../../util/i18n";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog } from "mdb-react-ui-kit";
import { usePayload } from "../../util/token";

export default function UserEdit() {
    let { 0: __ } = useLocale();
    let { 0: password, 1: setPassword } = React.useState('');
    let { 0: setCrumbs } = useCrumbs();
    let { 0: payload, 2: logout } = usePayload();
    let columns = React.useMemo<Columns>(() => ({
        [__('General')]: [
            {
                name: 'id',
                type: 'hidden'
            },
            {
                name: 'role',
                label: __('Role'),
                type: 'select',
                emptyOption: __('Administrator'),
                format: value => value?.toString() || '',
                load: () => new Promise((resolve, reject) => {
                    getRole({ limit: -1 }).then(response => {
                        if (typeof response === 'string') {
                            reject(response)
                        } else {
                            let options = [];
                            for (let i of (response.data || [])) {
                                options.push({ value: i.id.toString(), label: i.name });
                            }
                            resolve(options);
                        }
                    }, reject);
                })
            },
            {
                name: 'username',
                required: true,
                label: __('Username')
            },
            {
                name: 'email',
                type: 'email',
                label: __('Email Address')
            },
            {
                name: 'status',
                label: __('Status'),
                type: 'select',
                required: true,
                options: [
                    { value: '1', label: __('Enabled') },
                    { value: '0', label: __('Disabled') }
                ]
            }
        ]
    }), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        let p = getUser({ filter: { id: params.id }, limit: 1 });
        return new CancelablePromise<UserData>((resolve, reject) => p.then(response => {
            if (typeof response !== 'string' && response.data.length) {
                resolve(response.data[0]);
            } else {
                reject(response);
            }
        }), p.doCancel);
    });
    const doSave = React.useRef((data: FormData) => {
        saveUser(data).then(response => Toast.show(response, 'success'));
    });
    const doDelete = React.useRef((data: Record<string, any>) => {
        if (window.confirm(__('Are you sure to delete this?'))) {
            deleteUser(data.id).then(response => Toast.show(response, 'success'));
        }
    });
    let resetPwd = React.useCallback((id: string, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        changePassword({ user_id: parseInt(id), password: '1' }).then(response => {
            typeof response !== 'string' && setPassword(response.password);
            if (parseInt(id) === payload?.admin) {
                logout();
            }
        });
    }, [setPassword, payload, logout]);
    const btns = React.useCallback((data: Record<string, any>) => {
        return data.id ? (
            <MDBBtn type="button" color="info" onClick={setPassword.bind(null, data.id)}>{__('Reset Password')}</MDBBtn>
        ) : null
    }, [__, setPassword]);
    React.useEffect(() => {
        setCrumbs([{ label: 'User Management', link: '/user' }, 'Edit']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Edit %s').replace('%s', __('User'))}</title>
            </Helmet>
            <Edit id="id" columns={columns} doLoad={doLoad.current} doSave={doSave.current} doDelete={doDelete.current} btns={btns} />
            <MDBModal open={Boolean(password)} onClose={setPassword.bind(null, '')}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalBody className="d-flex flex-column align-items-center">
                            {Number.isNaN(parseInt(password)) ? (
                                <>
                                    <h4>{__('The new password is')}</h4>
                                    <p>{password}</p>
                                    <MDBBtn type="button" color="secondary" className="mt-2" onClick={setPassword.bind(null, '')}>{__('Close')}</MDBBtn>
                                </>
                            ) : (
                                <>
                                    <h4>{__('Click Reset button to reset password')}</h4>
                                    <div className="d-flex gap-3 mt-3">
                                        <MDBBtn type="button" color="secondary" onClick={setPassword.bind(null, '')}>{__('Close')}</MDBBtn>
                                        <MDBBtn type="button" onClick={resetPwd.bind(null, password)}>{__('Reset')}</MDBBtn>
                                    </div>
                                </>
                            )}
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}
