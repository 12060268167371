import React from "react";
import { getStock, getStockHistory, type GridConfig, type ParamsStockIn, type StockData, type StockHistoryData, stockIn, stockOut } from "../../api/stock";
import { MDBBadge, MDBBtn, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import Toast from "../../component/toast";
import { ModalRef, parseSku, parseCode } from "../../util/misc";
import { saveConfig } from "../../api/core";
import Pager from "../../component/pager";
import { Link } from "react-router-dom";
import ExcelJS from "exceljs";
import { getStockQty, type SkuQty } from "../../api/erp";
import BigNumber from "bignumber.js";

export default function Stock() {
    let [data, setData] = React.useState<{ [key: string]: Array<StockData> }>({});
    let [config, setConfig] = React.useState<Array<GridConfig>>([]);
    let [setCrumbs] = useCrumbs();
    let [__] = useLocale();
    let afterStockIn = React.useCallback((params: ParamsStockIn) => {
        let cloned = { ...data }
        cloned[params.area + '-' + params.row + '-' + params.col] = [];
        for (let sku in params.sku) {
            cloned[params.area + '-' + params.row + '-' + params.col].push({
                ...params,
                sku: sku,
                qty: params.sku[sku]
            });
        }
        // setData(cloned);
        calcPickingQty(cloned, config);
    }, [data, setData, config, __]);
    let afterStockOut = React.useCallback((params: Array<[number, number, number]>) => {
        let cloned = { ...data };
        for (const [area, row, col] of params) {
            delete cloned[area + '-' + row + '-' + col];
        }
        // setData(cloned);
        calcPickingQty(cloned, config);
    }, [data, setData, config, __]);
    const historyRef = React.useRef<ModalRef<[number, number, number]>>(null);
    const configRef = React.useRef<ConfigRef>(null);
    const stockInRef = React.useRef<StockInRef>(null);
    let doSaveConfig = React.useCallback((data: GridConfig, index: number) => new Promise<void>(resolve => {
        let cloned = [...config], bak = [...config];
        if (index < 0) {
            cloned.push(data);
        } else {
            cloned[index] = { ...(cloned[index] || {}), ...data };
        }
        setConfig(cloned);
        saveConfig({ path: 'stock/box', value: JSON.stringify(cloned) }).then(() => resolve(), () => {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
            setConfig(bak);
        });
    }), [config, setConfig, __]);
    let doDeleteConfig = React.useCallback((index: number) => new Promise<void>(resolve => {
        if (index < 0) {
            resolve();
            return;
        }
        let cloned = [...config], bak = [...config];
        cloned.splice(index, 1);
        setConfig(cloned);
        saveConfig({ path: 'stock/box', value: JSON.stringify(cloned) }).then(() => resolve(), () => {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
            setConfig(bak);
        });
    }), [config, setConfig, __]);
    let doEnable = React.useCallback((area: number, row: number, col: number) => {
        let cloned = [...config], bak = [...config];
        cloned[area].disabled ??= [];
        let target = cloned[area].disabled.findIndex(i => i[0] == row && i[1] == col);
        cloned[area].disabled.splice(target, 1);
        setConfig(cloned);
        saveConfig({ path: 'stock/box', value: JSON.stringify(cloned) }).catch(() => {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
            setConfig(bak);
        });
    }, [config, setConfig, __]);
    let doDisable = React.useCallback((area: number, row: number, col: number) => {
        let cloned = [...config], bak = [...config];
        cloned[area].disabled ??= [];
        cloned[area].disabled.push([row, col]);
        setConfig(cloned);
        saveConfig({ path: 'stock/box', value: JSON.stringify(cloned) }).catch(() => {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
            setConfig(bak);
        });
    }, [config, setConfig, __]);
    let setPickingArea = React.useCallback((area: number, row: number, e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        let cloned = [...config], bak = [...config];
        cloned[area].picking ??= [];
        let p = cloned[area].picking.indexOf(row);
        if (p === -1) {
            cloned[area].picking.push(row);
        } else {
            cloned[area].picking.splice(p, 1);
        }
        setConfig(cloned);
        saveConfig({ path: 'stock/box', value: JSON.stringify(cloned) }).catch(() => {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
            setConfig(bak);
        });
    }, [config, setConfig]);
    let calcPickingQty = React.useCallback(async (data: { [key: string]: Array<StockData> }, config: GridConfig[]) => {
        let picking: { [key: number]: Array<number> } = {};
        for (let area in config) {
            if (config[area].picking) {
                picking[area] = config[area].picking;
            }
        }
        let skuMap: { [code: string]: string } = {};
        let pickingData: { [sku: string]: { area: number, row: number, col: number, qty: BigNumber } } = {};
        for (let p in data) {
            if (picking[data[p][0].area]?.includes(parseInt(data[p][0].row.toString()))) {
                for (let item of data[p]) {
                    let sku = skuMap[item.sku] ?? (skuMap[item.sku] = parseSku(item.sku));
                    pickingData[sku] = { area: item.area, row: item.row, col: item.col, qty: (new BigNumber(0)) };
                }
            }
        }
        for (let p in data) {
            for (let item of data[p]) {
                let sku = skuMap[item.sku] ?? (skuMap[item.sku] = parseSku(item.sku));
                if (pickingData[sku]?.area === item.area && !picking[item.area]?.includes(parseInt(item.row.toString()))) {
                    pickingData[sku].qty = pickingData[sku].qty.minus(item.qty);
                }
            }
        }
        let sku = Object.keys(pickingData);
        if (sku.length) {
            let map: { [sku: string]: BigNumber } = {};
            for (let item of (await getStockQty({ filter: { sku } }) as { data: Array<SkuQty> })?.data ?? []) {
                map[item.sku] = new BigNumber(item.qty.toString());
            }
            for (let sku in pickingData) {
                let l = pickingData[sku].area + '-' + pickingData[sku].row + '-' + pickingData[sku].col;
                for (let i in data[l]) {
                    if ((skuMap[data[l][i].sku] ?? parseSku(data[l][i].sku)) === sku) {
                        if (typeof map[sku] === 'undefined') {
                            data[l][i].qty = 0;
                            data[l][i].error = true;
                        } else {
                            data[l][i].qty = pickingData[sku].qty.plus(map[sku]).toNumber();
                        }
                    }
                }
            }
        }
        setData(data);
    }, [setData]);
    React.useEffect(() => {
        let l = () => {
            const file = new ExcelJS.Workbook();
            for (let i in config) {
                const sheet = file.addWorksheet(config[i].name || __('Area %d').replace('%d', (i + 1).toString()));
                let row = sheet.getRow(1);
                for (let c = 1; c <= config[i].cols; c++) {
                    sheet.mergeCells([1, 2 * c + 1, 1, 2 * c + 2]);
                    let cell = row.getCell(2 * c + 1);
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    cell.font = { bold: true, size: 18 };
                    cell.value = c;
                }
                for (let r = config[i].rows; r > 0; r--) {
                    sheet.mergeCells([2 * r, 1, 2 * r + 1, 2]);
                    let cell = sheet.getRow(2 * (config[i].rows - r + 1)).getCell(1);
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    cell.font = { bold: true, size: 18 };
                    cell.value = r;
                }
            }
            for (let key in data) {
                const { 0: w, 1: r, 2: c } = key.split('-').map((i, d) => parseInt(i) + (d ? 1 : 0));
                const sheet = file.getWorksheet(w + 1);
                if (sheet && data[key].length > 0) {
                    let qty: ExcelJS.Cell, sku: ExcelJS.Cell, horizontal: 'center' | 'left' = 'center';
                    if (data[key].length === 1) {
                        sheet.mergeCells([2 * (config[w].rows - r + 1), 2 * c + 1, 2 * (config[w].rows - r + 1), 2 * c + 2]);
                        sheet.mergeCells([2 * (config[w].rows - r + 1) + 1, 2 * c + 1, 2 * (config[w].rows - r + 1) + 1, 2 * c + 2]);
                        qty = sheet.getRow(2 * (config[w].rows - r + 1)).getCell(2 * c + 2);
                        sku = sheet.getRow(2 * (config[w].rows - r + 1) + 1).getCell(2 * c + 1);
                        qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
                        sku.border = { bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
                    } else {
                        sheet.mergeCells([2 * (config[w].rows - r + 1), 2 * c + 1, 2 * (config[w].rows - r + 1) + 1, 2 * c + 1]);
                        sheet.mergeCells([2 * (config[w].rows - r + 1), 2 * c + 2, 2 * (config[w].rows - r + 1) + 1, 2 * c + 2]);
                        qty = sheet.getRow(2 * (config[w].rows - r + 1)).getCell(2 * c + 2);
                        sku = sheet.getRow(2 * (config[w].rows - r + 1)).getCell(2 * c + 1);
                        qty.border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                        sku.border = { bottom: { style: 'thin' }, left: { style: 'thin' }, top: { style: 'thin' } };
                        horizontal = 'left';
                    }
                    qty.alignment = { vertical: 'middle', horizontal: 'right', wrapText: true };
                    qty.font = { size: 14 };
                    qty.value = '';
                    sku.alignment = { vertical: 'middle', horizontal, wrapText: true };
                    sku.font = { size: 14 };
                    sku.value = '';
                    for (let i of data[key]) {
                        sku.value += (sku.value.length ? '\n' : '') + parseSku(i.sku);
                        qty.value += (qty.value.length ? '\n' : '') + parseFloat(i.qty.toString());
                    }
                }
            }
            file.xlsx.writeBuffer().then(buffer => {
                let a = document.createElement('a');
                a.download = 'export.xlsx';
                a.href = URL.createObjectURL(new Blob([buffer]));
                document.body.append(a);
                a.click();
                URL.revokeObjectURL(a.href);
                a.remove();
            });
        };
        document.addEventListener('export', l);
        return () => document.removeEventListener('export', l);
    }, [config, data]);
    React.useEffect(() => {
        getStock().then(response => {
            if (typeof response !== 'string') {
                let data: { [key: string]: Array<StockData> } = {};
                for (let i of (response.data || [])) {
                    if (data[i.area + '-' + i.row + '-' + i.col]) {
                        data[i.area + '-' + i.row + '-' + i.col].push(i);
                    } else {
                        data[i.area + '-' + i.row + '-' + i.col] = [i];
                    }
                }
                // setData(data);
                setConfig(response.config);
                calcPickingQty(data, response.config);
            }
        });
        setCrumbs(['Stock Grid']);
    }, []);
    return (
        <div className="stock-grid" data-exportable="1">
            {config.map((config, idx) => (
                <MDBTable key={'table-' + idx} className="bg-white text-center mb-0" bordered striped small responsive>
                    <colgroup>
                        <col width="1" />
                        {(new Array(config.cols)).fill(1).map((_, j) => (<col key={idx + 'c' + j} />))}
                    </colgroup>
                    <MDBTableHead>
                        <tr>
                            <th>
                                {config.name || __('Area %d').replace('%d', (idx + 1).toString())}
                                <button type="button" title={__('Edit')} onClick={configRef.current?.bind(null, config, idx)}>
                                    <span className="fa fa-edit" />
                                </button>
                                <Link to={'/stock/history?area=' + idx} className="history" title={__('History')}>
                                    <span className="fa fa-clock-rotate-left" />
                                </Link>
                            </th>
                            {(new Array(config.cols)).fill(1).map((_, j) => (
                                <th key={idx + 'h' + j} style={{ width: 100 / config.cols + '%' }}>
                                    {j + 1}
                                    <Link to={'/stock/history?area=' + idx + '&col=' + j} className="history" title={__('History')}>
                                        <span className="fa fa-clock-rotate-left" />
                                    </Link>
                                </th>
                            ))}
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {(new Array(config.rows)).fill(1).map((_, i) => {
                            let row = config.rows - i - 1;
                            return (
                                <tr key={idx + '-' + (row + 1)}>
                                    <th>
                                        {row + 1}
                                        {config.picking?.includes(row) ? (
                                            <button type="button" title={__('Picking Area')} onClick={setPickingArea.bind(null, idx, row)} style={{ opacity: 1 }}>
                                                <span className="fa fa-code-compare" />
                                            </button>
                                        ) : (
                                            <>
                                                <Link to={'/stock/history?area=' + idx + '&row=' + row} className="history" title={__('History')}>
                                                    <span className="fa fa-clock-rotate-left" />
                                                </Link>
                                                <button type="button" title={__('Picking Area')} onClick={setPickingArea.bind(null, idx, row)}>
                                                    <span className="fa fa-code-compare" />
                                                </button>
                                            </>
                                        )}
                                    </th>
                                    {(new Array(config.cols)).fill(1).map((_, j) => {
                                        let key = idx + '-' + row + '-' + j;
                                        return (
                                            <td key={key}>
                                                {(config.disabled || []).find(t => t[0] === row && t[1] === j) ? (
                                                    <button type="button" title={__('Disabled')} className="banned" onClick={doEnable.bind(null, idx, row, j)}>
                                                        <span className="fa fa-ban" />
                                                    </button>
                                                ) : (data[key]?.length ? (
                                                    <>
                                                        {data[key].map((item, i) => (
                                                            <React.Fragment key={item.sku + i}>
                                                                {i === 0 ? null : (<hr />)}
                                                                <span className={item.error === true || item.qty < 0 ? 'text-danger' : undefined}>{parseSku(item.sku) || (<span className="fa fa-xmark fa-2x text-danger" />)}</span>
                                                                <MDBBadge>{parseFloat(item.qty.toString())}</MDBBadge>
                                                            </React.Fragment>
                                                        ))}
                                                        <button type="button" title={__('Stock Out')} onClick={stockInRef.current?.show.bind(null, [idx, row, j], data[key], config.picking?.includes(row) ?? false)}>
                                                            <span className="fa fa-dolly" />
                                                        </button>
                                                        {config.picking?.includes(row) ? null : (
                                                            <button type="button" className="history" title={__('History')} onClick={() => historyRef.current?.show([idx, row, j])}>
                                                                <span className="fa fa-clock-rotate-left" />
                                                            </button>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span>&nbsp;</span>
                                                        <button type="button" title={__('Stock In')} onClick={stockInRef.current?.show.bind(null, [idx, row, j], [], config.picking?.includes(row) ?? false)}>
                                                            <span className="fa fa-plus" />
                                                        </button>
                                                        <button type="button" title={__('Disable')} onClick={doDisable.bind(null, idx, row, j)}>
                                                            <span className="fa fa-ban" />
                                                        </button>
                                                        {config.picking?.includes(row) ? null : (
                                                            <button type="button" className="history" title={__('History')} onClick={() => historyRef.current?.show([idx, row, j])}>
                                                                <span className="fa fa-clock-rotate-left" />
                                                            </button>
                                                        )}
                                                    </>
                                                ))}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </MDBTableBody>
                </MDBTable>
            ))}
            <History config={config} ref={historyRef} />
            <Config doSave={doSaveConfig} doDelete={doDeleteConfig} ref={configRef} />
            <StockIn afterStockIn={afterStockIn} afterStockOut={afterStockOut} ref={stockInRef} />
        </div>
    );
}

type ConfigRef = (config: GridConfig, i: number) => void;
interface ConfigProps {
    doSave: (config: GridConfig, index: number) => Promise<void>;
    doDelete: (index: number) => Promise<void>;
}

const Config = React.forwardRef<ConfigRef, ConfigProps>((props, ref) => {
    let [data, setData] = React.useState<GridConfig | null>(null);
    let [index, setIndex] = React.useState(-1);
    let [loading, setLoading] = React.useState(false);
    let [__] = useLocale();
    let onChange = React.useCallback((key: 'name' | 'rows' | 'cols', e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (data) {
            let value = e.target.value || '';
            if (key !== 'name') {
                let n = parseFloat(value);
                setData({ ...data, [key]: Number.isNaN(n) ? value : n });
            } else {
                setData({ ...data, [key]: value });
            }
        }
    }, [data, setData]);
    let onSubmit = React.useCallback((e: React.FormEvent) => {
        e.stopPropagation();
        e.preventDefault();
        let data = new FormData(e.target as HTMLFormElement);
        let params = {
            name: data.get('name') as string,
            rows: parseInt((data.get('rows') || '10') as string),
            cols: parseInt((data.get('cols') || '10') as string)
        };
        setLoading(true);
        props.doSave(params, index).then(() => {
            setLoading(false);
            setData(null);
        });
    }, [setData, props.doSave, setLoading, index]);
    let onDelete = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);
        props.doDelete(index).then(() => {
            setLoading(false);
            setData(null);
        });
    }, [setData, props.doSave, setLoading, index]);
    React.useImperativeHandle(ref, () => (
        (config, i) => {
            setData(config);
            setIndex(i);
        }
    ), [setData, setIndex]);
    React.useEffect(() => {
        let l = () => {
            setData({ rows: 10, cols: 10 });
            setIndex(-1);
        };
        window.setTimeout(() => {
            document.addEventListener('new', l);
        }, 300);
        return () => {
            document.removeEventListener('new', l);
        };
    }, [setData, setIndex]);
    return (
        <>
            <div hidden data-new-url="#" />
            <MDBModal open={Boolean(data)} onClose={setData.bind(null, null)} tabIndex='-1'>
                <MDBModalDialog centered size="sm">
                    <MDBModalContent tag="form" onSubmit={onSubmit}>
                        <MDBModalBody>
                            <MDBInput name="name" value={data?.name} label={__('Name')} className="active" onChange={onChange.bind(null, 'name')} />
                            <MDBInput type="number" name="rows" value={data?.rows} min={1} step={1} label={__('Rows')} className="active my-3" required onChange={onChange.bind(null, 'rows')} />
                            <MDBInput type="number" name="cols" value={data?.cols} min={1} step={1} label={__('Columns')} className="active" required onChange={onChange.bind(null, 'cols')} />
                        </MDBModalBody>
                        <MDBModalFooter>
                            {index < 0 ? null : (<MDBBtn type="button" color="danger" onClick={onDelete} size="sm">{__('Delete')}</MDBBtn>)}
                            <MDBBtn type="button" color="secondary" onClick={setData.bind(null, null)} size="sm">{__('Close')}</MDBBtn>
                            <MDBBtn type="submit" disabled={loading} size="sm">{__('Save')}</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
});

const History = React.forwardRef<ModalRef<[number, number, number]>, { config: Array<GridConfig> }>(function (props, ref) {
    let [position, setPosition] = React.useState<[number, number, number] | null>(null);
    let [data, setData] = React.useState<Array<StockHistoryData>>([]);
    let [total, setTotal] = React.useState(0);
    let [search, setSearch] = React.useState(new URLSearchParams([['page', '1'], ['limit', '10']]));
    let [__, lang] = useLocale();
    let formatter = React.useMemo(() => new Intl.DateTimeFormat(lang, { dateStyle: 'short', timeStyle: 'short' }), [lang]);
    React.useImperativeHandle(ref, () => ({
        show: setPosition,
        hide: () => {
            setPosition(null);
            setData([]);
        }
    }), [setPosition, setData]);
    React.useEffect(() => {
        if (position) {
            getStockHistory(position, search).then(response => {
                if (typeof response !== 'string') {
                    setData(response.data);
                    setTotal(response.total);
                }
            });
        }
    }, [position, search]);
    return (
        <MDBModal open={Boolean(position)} onClose={setPosition.bind(null, null)} tabIndex='-1'>
            <MDBModalDialog centered size="fullscreen">
                <MDBModalContent>
                    <MDBModalBody>
                        {data.length ? (
                            <MDBTable striped hover>
                                <colgroup>
                                    <col />
                                    <col width="1" />
                                    <col width="1" />
                                    <col width="1" />
                                </colgroup>
                                <MDBTableHead>
                                    <tr>
                                        <th>SKU</th>
                                        <th className="text-center">{__('Qty')}</th>
                                        <th className="text-center">{__('Stock In')}</th>
                                        <th className="text-center">{__('Stock Out')}</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {data.map((item, index) => (
                                        <tr key={'history-' + index}>
                                            <th>{parseSku(item.sku) || (<span className="fa fa-xmark fa-2x text-danger" />)}</th>
                                            <td className="text-center">{parseFloat(item.qty.toString())}</td>
                                            <td className="text-nowrap">{formatter.format(item.created_at * 1000)}</td>
                                            <td className="text-nowrap">{item.deleted_at ? formatter.format(item.deleted_at * 1000) : ''}</td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        ) : (
                            <span className="far fa-calendar-xmark fa-4x d-block text-center" />
                        )}
                    </MDBModalBody>
                    <MDBModalFooter className={data.length ? 'justify-content-between' : ''}>
                        {data.length ? (
                            <Pager total={total} search={search} onPage={setSearch} />
                        ) : null}
                        <MDBBtn type="button" color="secondary" onClick={setPosition.bind(null, null)}>{__('Close')}</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
});

interface StockInRef {
    show: (position: [number, number, number], items: Array<StockData>, picking: boolean) => void;
}

interface StockInProps {
    afterStockIn?: (params: ParamsStockIn) => void;
    afterStockOut?: (params: Array<[number, number, number]>) => void;
}

const StockIn = React.forwardRef<StockInRef, StockInProps>(function (props, ref) {
    let { 0: params, 1: setParams } = React.useState<Array<StockData> | null>(null);
    let { 0: picking, 1: setPicking } = React.useState(false);
    const position = React.useRef<[number, number, number]>();
    let { 0: __ } = useLocale();
    let onSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let data = new FormData(e.target as HTMLFormElement);
        let cloned: ParamsStockIn = { area: params![0].area, row: params![0].row, col: params![0].col, sku: {} };
        setParams(null);
        let skus = data.getAll('sku'), qtys = data.getAll('qty');
        for (let i = 0; i < skus.length; i++) {
            if (skus[i] && parseFloat(qtys[i] as string) > 0) {
                cloned.sku[parseCode(skus[i] as string)] = parseFloat(qtys[i] as string);
            }
        }
        if (Object.keys(cloned.sku).length === 0) {
            return;
        }
        try {
            await stockOut({ position: [position.current!] });
            await stockIn(cloned);
            props.afterStockIn?.(cloned);
            setParams(null);
        } catch {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
        }
    }, [params, setParams, props.afterStockIn]);
    let doStockOut = React.useCallback((e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        stockOut({ position: [position.current!] }).then(() => {
            props.afterStockOut?.([position.current!]);
            setParams(null);
        }, () => {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
        });
    }, [setParams, __, props.afterStockOut]);
    let onChange = React.useCallback((idx: number, e: React.ChangeEvent<HTMLInputElement>) => {
        setParams(old => {
            let cloned = old ? [...old] : [];
            cloned[idx] = {
                area: position.current![0],
                row: position.current![1],
                col: position.current![2],
                sku: cloned[idx]?.sku ?? '',
                qty: cloned[idx]?.qty ?? 1
            };
            if (e.target.name === 'sku') {
                cloned[idx].sku = parseCode(e.target.value);
            } else {
                cloned[idx].qty = parseFloat(e.target.value) || 1;
            }
            return cloned;
        });
    }, [setParams]);
    let more = React.useCallback((e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setParams(old => {
            return [...old!, {
                area: position.current![0],
                row: position.current![1],
                col: position.current![2],
                sku: '', qty: 1
            }];
        });
    }, [setParams]);
    React.useImperativeHandle(ref, () => ({
        show: (p, items, picking = false) => {
            position.current = p;
            setParams(items.length ? items : [{
                area: p[0], row: p[1], col: p[2],
                sku: '', qty: 1
            }]);
            setPicking(picking);
        }
    }), [setParams]);
    return (
        <MDBModal open={Boolean(params)} onClose={setParams.bind(null, null)}>
            <MDBModalDialog centered>
                <MDBModalContent tag="form" onSubmit={onSubmit}>
                    <MDBModalBody>
                        <h5 className="mb-3">{__('Please enter the SKU to stock in.')}</h5>
                        {params?.map((item, i) => (
                            <MDBInputGroup className="mb-3" key={'row-' + i}>
                                <MDBInput label="SKU" name="sku" value={parseSku(item.sku)} onChange={onChange.bind(null, i)} />
                                {picking ? (
                                    <input type="hidden" name="qty" value="1" />
                                ) : (
                                    <MDBInput label={__('Qty')} name="qty" min="1" value={parseFloat(item.qty.toString())} onChange={onChange.bind(null, i)} wrapperStyle={{ flexBasis: '3.5rem' }} />
                                )}
                            </MDBInputGroup>
                        ))}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="info" onClick={more}>{__('Add More Rows')}</MDBBtn>
                        <MDBBtn type="button" color="secondary" onClick={setParams.bind(null, null)}>{__('Close')}</MDBBtn>
                        {params?.length ? (
                            <MDBBtn type="button" color="danger" onClick={doStockOut}>{__('Stock Out')}</MDBBtn>
                        ) : null}
                        <MDBBtn type="submit">{__('Save')}</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
});
