import React from "react";
import { Helmet } from "react-helmet-async";
import Grid, { Columns } from "../../component/Grid";
import { useCrumbs } from "../../component/breadcrumbs";
import { deleteRole, getRole, type RoleData } from "../../api/admin";
import { useNavigate, useSearchParams } from "react-router-dom";
import useLocale from "../../util/i18n";
import Toast from "../../component/toast";

export default function RoleGrid() {
    let [query, setQuery] = useSearchParams();
    let [setCrumbs] = useCrumbs();
    let [__] = useLocale();
    let navigate = useNavigate();
    let columns = React.useMemo<Columns>(() => ({
        id: {
            label: 'ID'
        },
        name: {
            label: __('Name')
        },
        status: {
            label: __('Status'),
            className: 'w-1 text-center text-nowrap',
            filterable: 'status',
            type: 'select',
            options: [
                { value: '1', label: __('Enabled') }, { value: '0', label: __('Enabled') }
            ],
            format: (value: number) => value > 0 ? __('Enabled') : __('Enabled')
        }
    }), [__]);
    let actions = React.useMemo(() => ([
        {
            label: __('Edit'),
            onClick: (item: RoleData) => navigate('/role/edit?id=' + item.id)
        },
        {
            label: __('Delete'),
            onClick: (item: RoleData) => {
                if (window.confirm(__('Are you sure to delete this?'))) {
                    deleteRole({ id: item.id }).then(response => Toast.show(response));
                }
            }
        }
    ]), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        return getRole(params);
    });
    React.useEffect(() => {
        setCrumbs(['Role Management']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Role Management')}</title>
            </Helmet>
            <Grid newUrl="/role/edit" columns={columns} actions={actions} query={query} setQuery={setQuery} doLoad={doLoad.current} />
        </>
    );
}
