import React from 'react';
import './base.scss';
import { LocaleProvider } from './util/i18n';
import { Route, Routes } from 'react-router-dom';
import Header from './component/header';
import { HelmetProvider } from 'react-helmet-async';
import { PayloadProvider, usePayload } from './util/token';
import Dashboard from './page/Dashboard';
import Nav from './component/nav';
import Login from './page/Login';
import Toast from './component/toast';
import { CrumbsProvider } from './component/breadcrumbs';
import UserGrid from './page/user/Grid';
import UserEdit from './page/user/Edit';
import Me from './page/user/Me';
import RoleGrid from './page/role/Grid';
import RoleEdit from './page/role/Edit';
import ConfigGeneral from './page/config/General';
import ConfigEmail from './page/config/Email';
import ConfigStock from './page/config/Stock';
import Stock from './page/stock/Grid';
import Dispatch from './page/stock/Dispatch';
import History from './page/stock/History';
import ConfigInventory from './page/config/Inventory';
import ConfigAPI from './page/config/API';
import Forecast from './page/Forecast';

export default function App() {
    React.useEffect(() => {
        if ('ontouchend' in window) {
            document.documentElement.classList.add('touchevents');
        } else {
            document.documentElement.classList.add('notouchevents');
        }
    }, []);
    return (
        <Providers>
            <>
                <Content />
                <Toast />
            </>
        </Providers>
    );
}

function Providers(props: { children: JSX.Element }) {
    return (
        <LocaleProvider init={typeof localStorage !== 'undefined' ? localStorage.getItem('lang') || undefined : undefined}>
            <HelmetProvider>
                <CrumbsProvider>
                    <PayloadProvider>
                        {props.children}
                    </PayloadProvider>
                </CrumbsProvider>
            </HelmetProvider>
        </LocaleProvider>
    );
}

function Content() {
    let [payload] = usePayload();
    return payload?.admin ? (
        <>
            <Header />
            <Nav />
            <main className="main-content">
                <Routes>
                    <Route index path="/" element={<Dashboard />} />
                    <Route path="/config/general" element={<ConfigGeneral />} />
                    <Route path="/config/email" element={<ConfigEmail />} />
                    <Route path="/config/stock" element={<ConfigStock />} />
                    <Route path="/config/inventory" element={<ConfigInventory />} />
                    <Route path="/config/api" element={<ConfigAPI />} />
                    <Route path="/stock" element={<Stock />} />
                    <Route path="/stock/history" element={<History />} />
                    <Route path="/stock/dispatch" element={<Dispatch />} />
                    <Route path="/user" element={<UserGrid />} />
                    <Route path="/user/edit" element={<UserEdit />} />
                    <Route path="/user/me" element={<Me />} />
                    <Route path="/role" element={<RoleGrid />} />
                    <Route path="/role/edit" element={<RoleEdit />} />
                    <Route path="/forecast" element={<Forecast />} />
                </Routes>
            </main>
        </>
    ) : (
        <Login />
    );
}
