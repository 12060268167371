import React from "react";
import { Helmet } from "react-helmet-async";
import Grid, { Columns } from "../../component/Grid";
import { useCrumbs } from "../../component/breadcrumbs";
import { changePassword, deleteUser, getUser, UserData } from "../../api/admin";
import { useNavigate, useSearchParams } from "react-router-dom";
import useLocale from "../../util/i18n";
import Toast from "../../component/toast";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog } from "mdb-react-ui-kit";
import { usePayload } from "../../util/token";
import env from "../../env.json";

export default function UserGrid() {
    let { 0: query, 1: setQuery } = useSearchParams();
    let { 0: password, 1: setPassword } = React.useState('');
    let { 0: setCrumbs } = useCrumbs();
    let { 0: __ } = useLocale();
    let { 0: payload, 2: logout } = usePayload();
    let navigate = useNavigate();
    let columns = React.useMemo<Columns>(() => ({
        id: {
            label: 'ID'
        },
        username: {
            label: __('Username')
        },
        email: {
            label: __('Email Address'),
            format: (value: string) => (<a href={'mailto:' + value} target="_blank" rel="noreferrer">{value}</a>)
        },
        nickname: {
            label: __('Nickname'),
            format: (value, item) => (<>
                <img src={item.avatar || env.DEFAULT_IMG} className="rounded me-2" width="24" height="24" />
                <span>{value || __('Anonymous')}</span>
            </>)
        },
        status: {
            label: __('Status'),
            className: 'w-1 text-center text-nowrap',
            filterable: 'status',
            type: 'select',
            options: [
                { value: '1', label: __('Enabled') }, { value: '0', label: __('Disabled') }
            ],
            format: (value: number) => value > 0 ? __('Enabled') : __('Disabled')
        }
    }), [__]);
    let actions = React.useMemo(() => ([
        {
            label: __('Edit'),
            onClick: (item: UserData) => navigate('/user/edit?id=' + item.id)
        },
        {
            label: __('Reset Password'),
            onClick: (item: UserData) => {
                setPassword(item.id.toString());
            }
        },
        {
            label: __('Delete'),
            onClick: (item: UserData) => {
                if (window.confirm(__('Are you sure to delete this?'))) {
                    deleteUser({ id: item.id }).then(response => Toast.show(response));
                }
            }
        }
    ]), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        return getUser(params);
    });
    let resetPwd = React.useCallback((id: string, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        changePassword({ user_id: parseInt(id), password: '1' }).then(response => {
            typeof response !== 'string' && setPassword(response.password);
            if (parseInt(id) === payload?.admin) {
                logout();
            }
        });
    }, [setPassword, payload, logout]);
    React.useEffect(() => {
        setCrumbs(['User Management']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('User Management')}</title>
            </Helmet>
            <Grid newUrl="/user/edit" columns={columns} actions={actions} query={query} setQuery={setQuery} doLoad={doLoad.current} />
            <MDBModal open={Boolean(password)} onClose={setPassword.bind(null, '')}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalBody className="d-flex flex-column align-items-center">
                            {Number.isNaN(parseInt(password)) ? (
                                <>
                                    <h4>{__('The new password is')}</h4>
                                    <p>{password}</p>
                                    <MDBBtn type="button" color="secondary" className="mt-2" onClick={setPassword.bind(null, '')}>{__('Close')}</MDBBtn>
                                </>
                            ) : (
                                <>
                                    <h4>{__('Click Reset button to reset password')}</h4>
                                    <div className="d-flex gap-3 mt-3">
                                        <MDBBtn type="button" color="secondary" onClick={setPassword.bind(null, '')}>{__('Close')}</MDBBtn>
                                        <MDBBtn type="button" onClick={resetPwd.bind(null, password)}>{__('Reset')}</MDBBtn>
                                    </div>
                                </>
                            )}
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}
