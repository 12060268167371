import request from "../util/client";
import { ListParams, ListResponse } from "./types";

interface PurchaseOrder {
    id: string;
    order_id: string;
    ordered_at: number;
    delivered_at: number;
    received_at: number;
    status: string;
}

export interface PurchaseOrderItem {
    id: string;
    order_id: string;
    sku: string;
    ordered_qty: number;
    receipt_qty: number;
    delivered_at: number | null;
    ordered_at: number;
}

export function getPurchaseOrderItem(params: ListParams<any>) {
    return request<ListResponse<PurchaseOrderItem>>('purchase_order/item', 'GET', params);
}

export interface SkuQty {
    sku: string;
    qty: number;
    price?: number;
}

export function getSoldQty(params: ListParams<any>) {
    return request<ListResponse<SkuQty>>('sales_order/qty', 'GET', params);
}

export function getStockQty(params: ListParams<any>) {
    return request<ListResponse<SkuQty>>('stock_keeping', 'POST', params);
}

export interface Product {
    sku: string;
    price: number;
    width: number;
    height: number;
    depth: number;
}

export function getProducts(params: ListParams<any>) {
    return request<ListResponse<Product>>('product', 'GET', params);
}
