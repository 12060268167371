import React from "react";
import { Helmet } from "react-helmet-async";
import Edit, { Columns } from "../../component/Edit";
import { deleteRole, getRole, getOperation, saveRole, type RoleData, type OperationData } from "../../api/admin";
import Toast from "../../component/toast";
import { CancelablePromise } from "../../util/client";
import { useCrumbs } from "../../component/breadcrumbs";
import useLocale from "../../util/i18n";

export default function RoleEdit() {
    let [__] = useLocale();
    let [setCrumbs] = useCrumbs();
    let columns = React.useMemo<Columns>(() => ({
        [__('General')]: [
            {
                name: 'id',
                type: 'hidden'
            },
            {
                name: 'name',
                required: true,
                label: __('Name'),
                type: 'locale'
            },
            {
                name: 'status',
                label: __('Status'),
                type: 'select',
                required: true,
                options: [
                    { value: '1', label: __('Enabled') },
                    { value: '0', label: __('Disabled') }
                ]
            }
        ],
        [__('Privilege')]: [
            {
                name: 'operation[]',
                type: 'component',
                component: Operations
            }
        ]
    }), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        let p = getRole({ filter: { id: params.id }, limit: 1 });
        return new CancelablePromise<RoleData>((resolve, reject) => p.then(response => {
            if (typeof response !== 'string' && response.data.length) {
                resolve(response.data[0]);
            } else {
                reject(response);
            }
        }), p.doCancel);
    });
    const doSave = React.useRef((data: FormData) => {
        saveRole(data).then(response => Toast.show(response, 'success'));
    });
    const doDelete = React.useRef((data: Record<string, any>) => {
        if (window.confirm(__('Are you sure to delete this?'))) {
            deleteRole({ id: data.id }).then(response => Toast.show(response, 'success'));
        }
    });
    React.useEffect(() => {
        setCrumbs([{ label: 'Role Management', link: '/role' }, 'Edit']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Edit %s').replace('%s', __('Role'))}</title>
            </Helmet>
            <Edit id="id" columns={columns} doLoad={doLoad.current} doSave={doSave.current} doDelete={doDelete.current} />
        </>
    );
}

function Operations() {
    let { 0: operations, 1: setOperations } = React.useState<Array<OperationData>>([]);
    let { 0: __ } = useLocale();
    React.useEffect(() => {
        getOperation().then(response => {
            typeof response !== 'string' && setOperations(response.data);
        });
    }, []);
    return (
        <div className="row">
            {operations.map((operation => (
                <label key={operation.code} className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <input type="checkbox" name="operation[]" className="me-2" value={operation.code} />
                    <span>{__(operation.description)}</span>
                </label>
            )))}
        </div>
    );
}
