import request, { CancelablePromise, download } from "../util/client";
import { ListParams, ListResponse } from "./types";

export interface StockData {
    area: number;
    row: number;
    col: number;
    sku: string;
    qty: number;
    error?: boolean;
}

export interface GridConfig {
    name?: string;
    rows: number;
    cols: number;
    disabled?: Array<[number, number]>;
    picking?: Array<number>;
}

export interface StockHistoryData {
    sku: string;
    qty: number;
    created_at: number;
    deleted_at: number;
}

export function getStock() {
    return request<{ data: Array<StockData>, map: string, config: Array<GridConfig> }>('stock', 'GET');
}

interface getStockHistoryParmas {
    area?: number;
    row?: number;
    col?: number;
    page?: number;
    limit?: number;
}

export function getStockHistory(data: getStockHistoryParmas): CancelablePromise<ListResponse<StockHistoryData>>;
export function getStockHistory(position: [number, number, number], search: URLSearchParams): CancelablePromise<ListResponse<StockHistoryData>>;
export function getStockHistory(position: [number, number, number] | getStockHistoryParmas, search?: URLSearchParams) {
    let params: getStockHistoryParmas;
    if (Array.isArray(position)) {
        params = {
            area: position[0], row: position[1], col: position[2],
            page: parseInt(search?.get('page') || '1'),
            limit: parseInt(search?.get('limit') || '10')
        };
    } else {
        params = position;
    }
    return request<ListResponse<StockHistoryData>>('stock/history', 'GET', params);
}

export function stockOut(params: { position: Array<[number, number, number]> }) {
    return request<string>('stock', 'DELETE', params);
}

export interface ParamsStockIn {
    area: number;
    row: number;
    col: number;
    sku: {
        [sku: string]: number
    };
}

export function stockIn(params: ParamsStockIn) {
    return request<string>('stock', 'PUT', params);
}

export interface DispatchData {
    container_id: number;
    sku: string;
    qty: number;
    epcs: Array<string>
}

export function stockDispatch(container?: string) {
    return request<Array<DispatchData>>('stock/out', 'GET', container ? { filter: { container } } : '');
}

export function exportHistory(container: string | Array<string>) {
    return download('stock/out', 'POST', { container });
}

export interface ContainerData {
    id: number;
    code: string;
    info: any;
    status: number;
    created_at: number;
}

export function getContainer(params: ListParams<Partial<ContainerData>> | { entries: () => IterableIterator<[string, string]> }) {
    return request<ListResponse<ContainerData>>('container', 'GET', params);
}
