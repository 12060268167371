import { MDBBtn } from 'mdb-react-ui-kit';
import React from 'react';
import { NavLink } from "react-router-dom";
import useLocale from '../util/i18n';
import { usePayload, useRbac } from '../util/token';

export default function Nav() {
    const container = React.useRef<HTMLElement>(null);
    let [__] = useLocale();
    let [isGranted] = useRbac();
    let [payload] = usePayload();
    const onClick = React.useRef((e: React.MouseEvent<HTMLElement> | { target: HTMLElement | null }) => {
        if (e instanceof Event) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (!(e.target as HTMLElement).classList.contains('nav-link')) {
            onClick.current.call(null, {
                target: (e.target as HTMLElement).parentElement
            });
            return;
        }
        let n = (e.target as HTMLElement).nextElementSibling;
        if (!n) {
            return;
        }
        if (n.classList.contains('active')) {
            doDismiss.current.call(null, false);
        } else {
            doDismiss.current.call(null, true);
            container.current?.classList.add('active');
            n.classList.add('active');
        }
    });
    const doDismiss = React.useRef((e: React.MouseEvent<HTMLElement> | boolean) => {
        if (e instanceof Event) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (e !== true) {
            container.current?.classList.remove('active');
        }
        container.current?.querySelector('.nav.active')?.classList.remove('active');
    });
    return !payload || payload.admin == 3 ? null : (
        <nav className="nav-container" ref={container}>
            <div className="mask" onClick={doDismiss.current}></div>
            <ul className="nav flex-column">
                <li className="nav-item">
                    <NavLink to="/" className={({ isActive }) => "nav-link" + (isActive ? ' active' : '')}>
                        <span className="fa fa-fw fa-chart-line" />
                        <span className="tip">{__('Dashboard')}</span>
                    </NavLink>
                </li>
                {isGranted('getStock') ? (
                    <li className="nav-item">
                        <span className="nav-link" onClick={onClick.current}>
                            <span className="fa fa-fw fa-boxes-stacked" />
                            <span className="tip">{__('Stock')}</span>
                        </span>
                        <dl className="nav flex-column">
                            <dt className="nav-title">
                                <span>{__('Stock Management')}</span>
                                <MDBBtn color="link" onClick={doDismiss.current}>
                                    <span className="fa fa-times" />
                                </MDBBtn>
                            </dt>
                            <dd className="nav-item">
                                <NavLink to="/stock" className={({ isActive }) => "nav-link" + (isActive ? ' active' : '')}>
                                    <span>{__('Stock Grid')}</span>
                                </NavLink>
                            </dd>
                            <dd className="nav-item">
                                <NavLink to="/stock/history" className={({ isActive }) => "nav-link" + (isActive ? ' active' : '')}>
                                    <span>{__('Grid History')}</span>
                                </NavLink>
                            </dd>
                            <dd className="nav-item">
                                <NavLink to="/stock/dispatch" className={({ isActive }) => "nav-link" + (isActive ? ' active' : '')}>
                                    <span>{__('Stock Out History')}</span>
                                </NavLink>
                            </dd>
                            <dd className="nav-item">
                                <NavLink to="/forecast" className={({ isActive }) => "nav-link" + (isActive ? ' active' : '')}>
                                    <span>{__('Stock Forecast')}</span>
                                </NavLink>
                            </dd>
                        </dl>
                    </li>
                ) : null}
                {isGranted('getAdminUser') ? (
                    <li className="nav-item">
                        <span className="nav-link" onClick={onClick.current}>
                            <span className="fa fa-fw fa-users" />
                            <span className="tip">{__('User Management')}</span>
                        </span>
                        <dl className="nav flex-column">
                            <dt className="nav-title">
                                <span>{__('User Management')}</span>
                                <MDBBtn color="link" onClick={doDismiss.current}>
                                    <span className="fa fa-times" />
                                </MDBBtn>
                            </dt>
                            <dd className="nav-item">
                                <NavLink to="/user/me" className={({ isActive }) => "nav-link" + (isActive ? ' active' : '')}>
                                    <span>{__('My Profile')}</span>
                                </NavLink>
                            </dd>
                            <dd className="nav-item">
                                <NavLink to="/user" className={({ isActive }) => "nav-link" + (isActive ? ' active' : '')}>
                                    <span>{__('User Management')}</span>
                                </NavLink>
                            </dd>
                            {isGranted('getAdminRole') ? (
                                <dd className="nav-item">
                                    <NavLink to="/role" className={({ isActive }) => "nav-link" + (isActive ? ' active' : '')}>
                                        <span>{__('Role Management')}</span>
                                    </NavLink>
                                </dd>
                            ) : null}
                        </dl>
                    </li>
                ) : (
                    <li className="nav-item">
                        <NavLink to="/user/me" className={({ isActive }) => "nav-link" + (isActive ? ' active' : '')}>
                            <span className="fa fa-fw fa-user" />
                            <span className="tip">{__('My Profile')}</span>
                        </NavLink>
                    </li>
                )}
                <li className="nav-item">
                    <span className="nav-link" onClick={onClick.current}>
                        <span className="fa fa-fw fa-cogs" />
                        <span className="tip">{__('Settings')}</span>
                    </span>
                    <dl className="nav flex-column">
                        <dt className="nav-title">
                            <span>{__('Settings')}</span>
                            <MDBBtn color="link" onClick={doDismiss.current}>
                                <span className="fa fa-times" />
                            </MDBBtn>
                        </dt>
                        <dd className="nav-item">
                            <NavLink to="/config/inventory" className={({ isActive }) => "nav-link" + (isActive ? ' active' : '')}>
                                <span>{__('Inventory Settings')}</span>
                            </NavLink>
                        </dd>
                        <dd className="nav-item">
                            <NavLink to="/config/api" className={({ isActive }) => "nav-link" + (isActive ? ' active' : '')}>
                                <span>{__('API Settings')}</span>
                            </NavLink>
                        </dd>
                    </dl>
                </li>
            </ul>
        </nav>
    );
}
